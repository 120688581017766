/* This example requires Tailwind CSS v2.0+ */
import React, {useState, useEffect} from 'react';
import { Disclosure } from '@headlessui/react';
import { useUserAuth } from '../../Context/UserAuthContext';
import styled from 'styled-components';
import SecondaryButton from '../Buttons/SecondaryButton';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import PrimaryButton from '../Buttons/PrimaryButton';
import UserProfileMenu from './UserProfileMenu';
import Faqs from '../../Pages/FAQs/Faqs';
import IconCart from '../Icons/IconCart';
import CartCounter from '../CartCounter/CartCounter';
import { getStorage, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import FLMRLogo from '../../Images/logo/FLMR';

function NavBar() {

  const storage = getStorage();
  const firestoredb = getFirestore();
  const { user, 
          logOut, 
          userProfile,
        } = useUserAuth();
  const [avatar, setAvatar] = useState('');
  const { continent, country, county, state, surfspot } = useParams();

  useEffect(() => {
    if (user) {
      const profileImgRef = sRef(storage, `user_images/${user.uid}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          console.log(url);
          setAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [userProfile]);

  const handleLogOut = async () => {
    try {
      await logOut();
      <Link to="/"/>
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <>
      <Wrapper className="min-h-full sticky top-0 z-50">
        <Disclosure as="nav" className="bg-secondaryBg h-full">
          {({ open }) => (
            <>
              <div className="mx-auto px-6 innerwrapper">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 text-white">
                    <Link to="/"><FLMRLogo/></Link>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex flex-row items-baseline space-x-4">
                        <ul className="flex flex-row">
                          <Link to="/"><li>HOME</li></Link>
                          {/* <Link to={'/North America/United States/Rhode Island/Misquamicut'}>
                            <li>BROWSE ALBUMS</li>
                          </Link> */}
                          <Link to={'/photographers/North America/United States/Rhode Island/Misquamicut'}>
                            <li>FIND PHOTOGRAPHERS</li>
                          </Link>
                          {/* <Link to="/faqs"><li>FAQs</li></Link> */}
                          { user ? <Link to={`/onboarding-start/${user.uid}`}><li>GET LISTED</li></Link> : <Link to='/signup' ><li>GET LISTED</li></Link> }
                          {/* <Link to="/contact"><li>CONTACT</li></Link> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      { user ? null : <Link to='/login'><SecondaryButton className="btn-login mr-4">LOGIN</SecondaryButton></Link>}
                      { user ? null : <Link to='/signup'><PrimaryButton className="btn-signup">SIGN UP</PrimaryButton></Link>}
                      {/* <Link to='/dashboard' className="dash mr-4">DASHBOARD</Link> */}
                      { user ? <Link to='/bookings' className="dash">BOOKINGS</Link> :  null }
                      {/* { user 
                        ? 
                        <Link to='/cart' className="ml-4">
                          <CartCounter />
                          <IconCart />
                        </Link> 
                        : null 
                      } */}
                      { user ? <UserProfileMenu /> : null }
                    </div>
                  </div>
                  <div className="flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-primaryBg text-xs inline-flex items-center justify-center py-1 px-3 rounded-md text-secondaryBg">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <p className="text-xs">CLOSE</p>
                      ) : (
                        <p className="text-xs">MENU</p>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden relative z-50 bg-primaryBg shadow-custom">
                <div className="px-2 pt-5 pb-3 sm:px-3">
                  <ul className="flex flex-row">
                    <Disclosure.Button className="flex flex-col w-full">
                      <Link to="/" className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">HOME</Link>
                      <Link to={'/photographers/North America/United States/Rhode Island/Misquamicut'} className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">FIND PHOTOGRAPHERS</Link>
                      { user ? 
                        <Link to={`/onboarding-start/${user.uid}`} className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">GET LISTED</Link> 
                        : 
                        <Link to={`/signup`} className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">GET LISTED</Link>
                      }
                      <Link to="/contact" className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">CONTACT</Link> 
                    </Disclosure.Button>
                  </ul> 
                  <Disclosure.Button className="flex flex-col w-full">
                    { user ? null : <Link to='/login' className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">LOGIN</Link> }
                    { user ? null : <Link to='/signup' className="dash w-full text-left text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">SIGN UP</Link> }
                  </Disclosure.Button>
                </div>
                {/* ONLY DISPLAY WHEN USER IS LOGGED IN */}
                <Disclosure.Button>
                {user ? <div className="pt-4 pb-3 border-t border-gray-700 w-screen">
                  <div className="flex flex-row items-center px-5">
                    <div className="flex-shrink-0">
                      <Link to="/profile">
                        <img className="h-10 w-10 rounded-full" src={avatar} alt="" style={{objectFit: 'cover'}}/>
                      </Link>
                    </div>
                    <div className="ml-3 text-left">
                      <div className="text-base leading-none text-white">
                        <Link to="/profile">
                          {user?.username}
                        </Link>
                      </div>
                      <div className="text-sm text-xs leading-none text-gray-400">
                        <Link to="/profile">
                          {user?.email}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 flex text-left flex-col">
                    {/* <Link to="/profile" className="dash text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">VIEW PROFILE</Link> */}
                    {/* <Link to="/cart" className="dash text-xs px-3 py-2 text-white hover:bg-secondaryBg rounded-md">VIEW CART</Link> */}
                    {/* <Link to={'/photographers/North America/United States/Rhode Island/Misquamicut'} className="dash px-3 py-2 hover:bg-secondaryBg rounded-md">FIND PHOTOGRAPHERS</Link> */}
                    <Link to='/bookings' className="dash px-3 py-2 hover:bg-secondaryBg rounded-md">MY BOOKINGS</Link>
                    <Link to='/profile' className="dash px-3 py-2 hover:bg-secondaryBg rounded-md">MY PROFILE</Link>
                    <Link to="" onClick={handleLogOut} className="dash px-3 py-2 hover:bg-secondaryBg rounded-md">SIGN OUT</Link>
                  </div>
                </div> : null}
                </Disclosure.Button>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  height: 80px;

  ul li {
    letter-spacing: 0.1em;
    font-size: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right:2rem;
    color: #CBCFD4;
  }

  ul li:hover {
    color: white;
  }

  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 4%), 
              0 2px 2px hsl(0deg 0% 0% / 4%), 
              0 4px 4px hsl(0deg 0% 0% / 4%), 
              0 8px 8px hsl(0deg 0% 0% / 4%), 
              0 16px 16px hsl(0deg 0% 0% / 4%);

  .shadow-custom {
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 4%), 
              0 2px 2px hsl(0deg 0% 0% / 4%), 
              0 4px 4px hsl(0deg 0% 0% / 4%), 
              0 8px 8px hsl(0deg 0% 0% / 4%), 
              0 16px 16px hsl(0deg 0% 0% / 4%);
  }

  .active-underline {
    background-image: linear-gradient(90deg, #F27A54, #A154F2);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: left bottom;
    color: white;
  }

  .btn-login button {
    border: 1px solid white;
    margin-right: 15px;
    height: 30px;
    width: 100px;
  }

  .btn-signup button {
    height: 30px;
    width: 100px;
  }

  .innerwrapper {
    height: 100%;
    display: grid;
    align-items: center;
  }

  .dash {
    color: #CBCFD4;
    font-size: 10px;
    // margin-right: 26px;
    letter-spacing: 0.1em;
  }
`
export default NavBar