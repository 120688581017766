import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import useGetUploadedAlbums from '../../Hooks/useGetUploadedAlbums';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyAlbumsCard from '../../Components/MyAlbumsCard/MyAlbumsCard';
import Modal from '../../Components/Modal/Modal';
import BlankModal from '../../Components/Modal/ModalBlank';
import { storage } from '../../Services/firebase/FirebaseConfig';
import { ref as sRef, deleteObject, listAll, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref, remove, onValue } from "firebase/database";
import { getFirestore, doc, deleteDoc, query, where, collection, getDocs } from "firebase/firestore";
import CreateAlbumBtn from '../../Components/Buttons/CreateAlbumBtn';
import { useUserAuth } from '../../Context/UserAuthContext';

interface Album {
  album_name: string,
  album_slug: string,
  id: any
}

function UsersAlbums() {
  const [albums, setAlbums] = useState([]);
  const [userAvatar, setUserAvatar] = useState('');
  const { user } = useUserAuth();
  const db = getFirestore();

  // Get users profile image , avatar
  useEffect(() => {
    const storageRef = storage; 
    getDownloadURL(sRef(storageRef, `user_images/${user.uid}/avatar/user_avatar.png`))
        .then((url) => {
          setUserAvatar(url);
        })
        .catch((error) => {
          console.log("Album Card Avatar Error: ", error);
        });
  }, []);
 
  // Get users albums
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "albums"), where("album_owner_id", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const usersAlbums:any = [];
      querySnapshot.forEach((doc) => {
        usersAlbums.push(doc.data());
      });
      setAlbums(usersAlbums);
    };
    fetchData(); // Call the async function
  }, []);
  
  // Delete From Storage
  // This grabs a reference to the clicked 'item', takes the album_slug which and grabs a reference of the firebase storage
  // using this slug. It then uses listAll to loop through each item in the album, and delete each one.
  // This deletes the entire content and folder from fb storage
  const deleteStorageFolder = (item: any) => { 
    const folderRef = sRef(storage, item.album_slug);

    listAll(folderRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        const newFolderRef = sRef(storage, itemRef.fullPath)
        deleteObject(newFolderRef)
          .then(() => {
            console.log("Storage Folder Deleted");
          })
          .catch((error) => {
            console.log(error);
          })
      });
    });
  }

  // Delete thumbnails storage folder 
  const deleteStorageThumbnails = (item: any) => { 
    const folderRef = sRef(storage, item.album_slug + '/thumbnails');

    listAll(folderRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        const newFolderRef = sRef(storage, itemRef.fullPath)
        deleteObject(newFolderRef)
          .then(() => {
            console.log("Thumbnails Deleted");
          })
          .catch((error) => {
            console.log(error);
          })
      });
    });
  }

  // Delete from firestore
  const deleteFirestoreEntry = async (item: any) => {
    // Create a reference to the "albums" collection.
    const albumsCollection = collection(db, "albums");
    // Create a query to find the document with the specified album_slug value.
    const q = query(albumsCollection, where("album_slug", "==", item.album_slug));
    try {
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Assuming there is only one matching document, but you can loop through querySnapshot.docs if needed.
        const albumDoc = querySnapshot.docs[0];

        // Delete the document using its ID.
        await deleteDoc(doc(albumsCollection, albumDoc.id));
        console.log(`Document with album_slug '${item.album_slug}' deleted successfully.`);
      } else {
        console.log(`Document with album_slug '${item.album_slug}' not found.`);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };


  // Delete from Realtime DB
  const deleteRealtimeDbEntry = (item: any) => {
    const db = getDatabase();
    const databaseRef = ref(db, 'albums/');
  
    onValue(databaseRef, (snapshot) => {
      const data: Album[] | null = snapshot.val();
      const rtdbEntries = data ? Object.entries(data) : [];
  
      const selectedItem = rtdbEntries.find(([, value]) => value.album_slug === item.album_slug);
  
      if (selectedItem) {
        const [key, value] = selectedItem;
  
        remove(ref(db, `albums/${key}`))
          .then(() => {
            console.log("rtdb entry deleted");
          })
          .catch((error) => {
            console.log("rtdb delete error: ", error);
          });
      }
    });
  }

  function handleDelete(index: number, item: any) {
    setAlbums(albums.filter((_, i) => i !== index));

    // Delete from storage
    deleteStorageFolder(item);
    // Delete from storage - thumbnails folder
    deleteStorageThumbnails(item);
    // Delete from realtime db
    deleteRealtimeDbEntry(item);
    // Delete firestore entry
    deleteFirestoreEntry(item);

    toast.success("Album has successfully been deleted", {
      theme: "dark"
    });
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <h1>My Albums</h1>
        <div className="p-4">
          <Link to="/newalbum">
            <CreateAlbumBtn variant="lite"/>
          </Link>
        </div>
      </HeaderWrapper>
      <HRFancy />
      {/* No albums message */}
      { albums.length > 0 ? null : 
          <NoSpotsWrapper>
            <h2>You haven't uploaded any albums yet.</h2>
          </NoSpotsWrapper> }
      <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-4">
      { albums.map((item, index) => {
            // console.log(item);
              return <CardWrapper key={index} className='shadow-lg flex flex-col'>
                      <MyAlbumsCard 
                        key={index}
                        album_continent={item.album_continent}
                        album_country={item.album_country}
                        album_county={item.album_county}
                        album_default_banner={item.album_default_banner}
                        album_description={item.album_description}
                        album_media_count={item.album_media_count}
                        album_media_price={item.album_media_price}
                        album_name={item.album_name}
                        album_owner_id={item.album_owner_id}
                        album_owner_username={item.album_owner_username}
                        album_slug={item.album_slug}
                        album_spot={item.album_spot}
                        album_state={item.album_state}
                        album_upload_date={item.album_upload_date}
                        session_date={item.session_date}
                        session_time={item.session_time}
                      />
                      <ButtonWrapper className='flex flex-row h-full items-end'>
                      <Link className='mr-4' to={`/albums/${item.album_owner_id}/${item.album_spot}/${item.album_name}`} 
                          state={{
                            albumLink: `/albums/${item.album_owner_id}/${item.album_spot}/${item.album_name}`,
                            album_spot: item.album_spot,
                            album_name: item.album_name,
                            owner: item.owner,
                            userName: item.userName,
                            mediaPrice: item.mediaPrice,
                            photoDate: item.albumSessionDate
                          }}><button>VIEW</button></Link>
                          {/* <BlankModal children={''} buttonText={'EDIT'} headerText={'Edit Album'} className={''} isOpen={false} onClose={function (): void {
                              throw new Error('Function not implemented.');
                            } } /> */}
                        {/* <a href="#" className=" mr-4"><button className="btn-profile">EDIT</button></a> */}
                        <Modal onDelete={() => handleDelete(index, item)}/>
                      </ButtonWrapper>
                    </CardWrapper>
            })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem;
  border-radius: 3px;
  
  p {
    line-height: 20px;
  }

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    padding: 1rem;
    font-size: 24px;
    font-weight: 900;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C444C;
  height: 69px;
`

const CardWrapper = styled.div`
  background-color: #3C444C;
  border-radius: 3px;

`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
`

const NoSpotsWrapper = styled.div`
  color: #30363d;
  background-color: #3C444C;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
`

const ButtonWrapper = styled.div`
  padding: 1rem;

  button, a {
    width: 100%;
    height: 35px;
    font-size: 10px;
    color: #CBCFD4;
    border-radius: 7px;
    background-color: #30363d;
  }

  button:hover {
    background-color: #1e2227;
    transition: ease all .2s;
  }
`

export default UsersAlbums